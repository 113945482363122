/**
 * @summary LANDING PAGES: This is any landing page for a microsite, https://domain.com/{slug}/landing.
 * This points to wordpress Landing Pages content types
 */
import React from 'react';
import PageContainer from 'components/global/PageContainer';
import PageSectionLayout from 'components/global/PageSectionLayout';
import { pagePropsType } from 'utils/propTypes';
import getLandingPageData from 'app-requests/content-type-loaders/landing-page-props';
import { isClickPortal } from 'utils/generalUtils';
import handleServerRedirect from 'utils/handleServerRedirect';
import { APIError } from 'utils/errors/APIError';
import { APITransformError } from 'utils/errors/APITransformError';
import { LogError } from 'utils/logging';
import {
  clickPortalRedirect,
  defaultRedirect,
  notFoundRedirect,
  serverMaintenanceRedirect,
} from 'consts';

function LandingPage(props) {
  const {
    pageProps: { pageSections, ogImg, ...globalPageProps },
  } = props;

  return (
    <PageContainer pageProps={{ ...globalPageProps, ogImg }}>
      <PageSectionLayout pageSections={pageSections} isLanding />
    </PageContainer>
  );
}

/**
 * @summary this is the Nextjs function that will get the server side props.
 * this will call all needed API to create the Landing Page
 */
export async function getServerSideProps(context) {
  // We only need to do this here because this is the main landing page that everything else would get redirected to.
  if (isClickPortal(context.req)) {
    handleServerRedirect(clickPortalRedirect, context);
    return { props: {} };
  }

  try {
    const pageProps = await getLandingPageData(context);
    return { props: pageProps };
  } catch (error) {
    if (error.statusCode === 404 && context.req.url !== defaultRedirect) {
      handleServerRedirect(defaultRedirect, context);
      return { props: {} };
    }

    if (error.statusCode === 404) {
      handleServerRedirect(notFoundRedirect, context);
      return { props: {} };
    }

    if (error instanceof APIError) {
      LogError(error, {
        statusCode: error.statusCode,
        endpoint: error.endpoint,
        route: 'landing',
      });
    } else if (error instanceof APITransformError) {
      LogError(error, {
        method: error.method,
        route: 'landing',
      });
    } else {
      LogError(error, {
        route: 'landing',
      });
    }

    handleServerRedirect(serverMaintenanceRedirect, context, 302);
    return { props: {} };
  }
}

LandingPage.propTypes = {
  pageProps: pagePropsType.isRequired,
};

export default LandingPage;
